import React from "react"
import { Row, Col, Container, Carousel, CardDeck, Card, Button } from "react-bootstrap"
import "./../styles/style.scss"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import CarouselImage01 from "./../images/lutein-zeaforce-slider-lifestyle-mobile.jpg";
import CarouselImage01x2 from "./../images/lutein-zeaforce-slider-lifestyle-tablet.jpg";
import CarouselImage01x3 from "./../images/lutein-zeaforce-slider-lifestyle-hd.jpg";

import CarouselImage02 from "./../images/lutein-restartirat-zrenieto-mobile.jpg"
import CarouselImage02x2 from "./../images/lutein-restartirat-zrenieto-tablet.jpg"
import CarouselImage02x3 from "./../images/lutein-restartirat-zrenieto-hd.jpg"

import CarouselImage03 from "./../images/hepatofelin-slider-lifestyle-mobile.jpg"
import CarouselImage03x2 from "./../images/hepatofelin-slider-lifestyle-tablet.jpg"
import CarouselImage03x3 from "./../images/hepatofelin-slider-lifestyle-hd.jpg"

import LuteinComplex from "./../images/Lutein-Complex-home-@1x.jpg"
import LuteinComplexx2 from "./../images/Lutein-Complex-home-@2x.jpg"
import LuteinComplexx3 from "./../images/Lutein-Complex-home-@3x.jpg"

import Hepatofelin from "./../images/Hepatofelin-home-@1x.jpg"
import Hepatofelinx2 from "./../images/Hepatofelin-home-@2x.jpg"
import Hepatofelinx3 from "./../images/Hepatofelin-home-@3x.jpg"

import ProstatizalP from "./../images/Prostatizal-Plus-home-@1x.jpg"
import ProstatizalPx2 from "./../images/Prostatizal-Plus-home-@2x.jpg"
import ProstatizalPx3 from "./../images/Prostatizal-Plus-home-@3x.jpg"

import PsoriazalP from "./../images/Psoriazal-Plus-home-@1x.jpg"
import PsoriazalPx2 from "./../images/Psoriazal-Plus-home-@2x.jpg"
import PsoriazalPx3 from "./../images/Psoriazal-Plus-home-@3x.jpg"

import LaksiproktImg from "./../images/laksiprokt.jpg"
import LiporeduktImg from "./../images/liporedukt.jpg"
import MenopauzalImg from "./../images/menopauzal.jpg"
import MiomalImg from "./../images/miomal.jpg"
import NevroherbImg from "./../images/nevroherb.jpg"
import PielonefralImg from "./../images/pielonefral.jpg"
import ProstatizalImg from "./../images/prostatizal.jpg"
import PsoriazalImg from "./../images/psoriazal.jpg"
import VeniprotektImg from "./../images/veniprotekt.jpg"

import TechImg from "./../images/licaps-tech.jpg"
import AboutImg from "./../images/about.jpg"
import StatiiImg from "./../images/statii.jpg"



const IndexPage = () => (
  <Layout
    announce={"ZeaForce® ще бъде новото име на Лутеин Комплекс Плюс ‣"}
    announceLink={"/lutein-complex"}
    pageInfo={{ pageName: "index" }}
  >
    <Seo
      title="Vita Herb"
      description="Вита Херб е водещ производител на хранителни добавки в България. От над 15 години фирмата работи в тясно сътрудничество с Capsugel - Франция, където се произвеждат по-голямата част от продуктите ни."
      keywords={[
        `Лутеин Комплекс`,
        `Хепатофелин`,
        `Простатизал Плюс`,
        `Псориазал Плюс`,
        `Вита Херб`,
      ]}
    />
    <Container className="px-0">
      <Row className="mb-3">
        <Col>
          <h5 className="section-heading">Избрани моменти</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Carousel
            indicators={false}
            fade={true}
            interval={3500}
            className="shadow-1"
          >
            <Carousel.Item>
              <Link to="/lutein-complex" className="link-no-style">
                <picture>
                  <source
                    srcSet={CarouselImage01x3}
                    media="(min-width: 992px)"
                  />
                  <source
                    srcSet={CarouselImage01x2}
                    media="(min-width: 767px)"
                  />
                  <source srcSet={CarouselImage01} media="(min-width: 370px)" />
                  <img
                    srcSet={CarouselImage01}
                    alt="Lutein Complex banner"
                    className="d-block w-100 rounded mb-3"
                  />
                </picture>
              </Link>

              <Carousel.Caption className="carousel-caption rounded-bottom p-2">
                <Link to="/lutein-complex" className="link-no-style">
                  ZeaForce<sup>®</sup> ще бъде новото име на Лутеин Комплекс Плюс
                </Link>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <Link
                to="/articles/restartirat-zrenieto"
                className="link-no-style"
              >
                <picture>
                  <source
                    srcSet={CarouselImage02x3}
                    media="(min-width: 992px)"
                  />
                  <source
                    srcSet={CarouselImage02x2}
                    media="(min-width: 767px)"
                  />
                  <source srcSet={CarouselImage02} media="(min-width: 370px)" />
                  <img
                    srcSet={CarouselImage02}
                    alt="Добра новина: Рестартират зрението"
                    className="d-block w-100 rounded mb-3"
                  />
                </picture>
              </Link>

              <Carousel.Caption className="carousel-caption rounded-bottom p-2">
                <Link
                  to="/articles/restartirat-zrenieto"
                  className="link-no-style"
                >
                  Добра новина: Рестартират зрението
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/hepatofelin" className="link-no-style">
                <picture>
                  <source
                    srcSet={CarouselImage03x3}
                    media="(min-width: 992px)"
                  />
                  <source
                    srcSet={CarouselImage03x2}
                    media="(min-width: 767px)"
                  />
                  <source srcSet={CarouselImage03} media="(min-width: 370px)" />
                  <img
                    srcSet={CarouselImage03}
                    alt="Hepatofelin banner"
                    className="d-block w-100 rounded mb-3"
                  />
                </picture>
              </Link>

              <Carousel.Caption className="carousel-caption rounded-bottom p-2">
                <Link to="/hepatofelin" className="link-no-style">
                  Хепатофелин - Сигурна защита за черния дроб
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item>
              <Link to="/stavien" className="link-no-style">
                <picture>
                  <source
                    srcSet={CarouselImage04x3}
                    media="(min-width: 992px)"
                  />
                  <source
                    srcSet={CarouselImage04x2}
                    media="(min-width: 767px)"
                  />
                  <source srcSet={CarouselImage04} media="(min-width: 370px)" />
                  <img
                    srcSet={CarouselImage04}
                    alt="Stavien - капсули хиалуронова киселина с Омега-3"
                    className="d-block w-100 rounded mb-3"
                  />
                </picture>
              </Link>

              <Carousel.Caption className="carousel-caption rounded-bottom p-2">
                <Link to="/stavien" className="link-no-style">
                  Stavien - стабилна опора за ставите
                </Link>
              </Carousel.Caption>
            </Carousel.Item> */}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className="section-heading">Продукти</h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="flex-row justify-content-start  ">
          <CardDeck className="mb-3 d-flex card-layout">
            <span className="left-rectangle d-lg-none"></span>

            <Link to="/lutein-complex" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={LuteinComplex}
                  srcSet={`${LuteinComplexx2} 2x, ${LuteinComplexx3} 3x`}
                  alt="Lutein Complex"
                />
                <Card.Body>
                  <Card.Title>Lutein Complex Plus</Card.Title>
                  <Card.Text className="p-card-text">
                    максимална подкрепа за зрението
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/hepatofelin" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={Hepatofelin}
                  srcSet={`${Hepatofelinx2} 2x, ${Hepatofelinx3} 3x`}
                  alt="Hepatofelin"
                />
                <Card.Body>
                  <Card.Title>Hepatofelin</Card.Title>
                  <Card.Text className="p-card-text">
                    подпомага функцията на черния дроб и жлъчката
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            {/* <Link to="/stavien" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={Stavien}
                  srcSet={`${Stavienx2} 2x, ${Stavienx3} 3x`}
                  alt="Stavien"
                />
                <Card.Body>
                  <Card.Title>Stavien</Card.Title>
                  <Card.Text className="p-card-text">
                    допринася за максимален
                    <br /> комфорт при движение
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link> */}

            <Link to="/prostatizal-plus" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={ProstatizalP}
                  srcSet={`${ProstatizalPx2} 2x, ${ProstatizalPx3} 3x`}
                  alt="Prostatizal Plus"
                />
                <Card.Body>
                  <Card.Title>Prostatizal Plus</Card.Title>
                  <Card.Text className="p-card-text">
                    укрепва здравето на простатата
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/psoriazal-plus" className="link-no-style">
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={PsoriazalP}
                  srcSet={`${PsoriazalPx2} 2x, ${PsoriazalPx3} 3x`}
                  alt="Psoriazal Plus"
                />
                <Card.Body>
                  <Card.Title>Psoriazal Plus</Card.Title>
                  <Card.Text className="p-card-text">
                    подкрепя здравето на кожата при псориазис
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <span className="right-rectangle d-lg-none"></span>
          </CardDeck>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h5 className="section-heading">Здравни теми</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Link to="/lutein-complex">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              очи
            </Button>
          </Link>
          <Link to="/hepatofelin">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              черен дроб
            </Button>
          </Link>
          <Link to="/prostatizal-plus">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              простата
            </Button>
          </Link>
          <Link to="/psoriazal-plus">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              кожа
            </Button>
          </Link>
          <Link to="/hepatofelin">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              жлъчка
            </Button>
          </Link>
          <Link to="/hepatofelin">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              чернодробни ензими
            </Button>
          </Link>
          <Link to="/lutein-complex">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              катаракта
            </Button>
          </Link>
          <Link to="/psoriazal-plus">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              псориазис
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="">
        <Col>
          <h5 className="section-heading">Билкови продукти O'Yes Vita</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="flex-row justify-content-start  ">
          <CardDeck className="mb-3 d-flex card-layout">
            <span className="left-rectangle d-lg-none"></span>

            <Link to="/laksiprokt" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={LaksiproktImg} alt="Лаксипрокт" />
                <Card.Body>
                  <Card.Title className="h5--sm">Лаксипрокт</Card.Title>
                  <Card.Text className="p-card-text">
                    при лениви черва
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/liporedukt" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={LiporeduktImg} alt="Липоредукт" />
                <Card.Body>
                  <Card.Title className="h5--sm">Липоредукт</Card.Title>
                  <Card.Text className="p-card-text">
                    при наднормено тегло
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/menopauzal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={MenopauzalImg} alt="Менопаузал" />
                <Card.Body>
                  <Card.Title className="h5--sm">Менопаузал</Card.Title>
                  <Card.Text className="p-card-text">при климакс</Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/miomal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={MiomalImg} alt="Миомал" />
                <Card.Body>
                  <Card.Title className="h5--sm">Миомал</Card.Title>
                  <Card.Text className="p-card-text">
                    за женската полова система
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/veniprotekt" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img
                  variant="top"
                  src={VeniprotektImg}
                  alt="Венипротект"
                />
                <Card.Body>
                  <Card.Title className="h5--sm">Венипротект</Card.Title>
                  <Card.Text className="p-card-text">
                    при разширени вени
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/nevroherb" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={NevroherbImg} alt="Неврохерб" />
                <Card.Body>
                  <Card.Title className="h5--sm">Неврохерб</Card.Title>
                  <Card.Text className="p-card-text">
                    за нервната система
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/pielonefral" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img
                  variant="top"
                  src={PielonefralImg}
                  alt="Пиелонефрал"
                />
                <Card.Body>
                  <Card.Title className="h5--sm">Пиелонефрал</Card.Title>
                  <Card.Text className="p-card-text">за бъбреци</Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/prostatizal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img
                  variant="top"
                  src={ProstatizalImg}
                  alt="Простатизал"
                />
                <Card.Body>
                  <Card.Title className="h5--sm">Простатизал</Card.Title>
                  <Card.Text className="p-card-text">
                    подкрепя простатата
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <Link to="/psoriazal" className="link-no-style">
              <Card className="product-card product-card--sm">
                <Card.Img variant="top" src={PsoriazalImg} alt="Псориазал" />
                <Card.Body>
                  <Card.Title className="h5--sm">Псориазал</Card.Title>
                  <Card.Text className="p-card-text">подкрепя кожата</Card.Text>
                </Card.Body>
              </Card>
            </Link>

            <span className="right-rectangle d-lg-none"></span>
          </CardDeck>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5 className="section-heading">Билкова подкрепа</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Link to="/psoriazal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              кожа
            </Button>
          </Link>
          <Link to="/pielonefral">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              бъбреци
            </Button>
          </Link>
          <Link to="/veniprotekt">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              вени
            </Button>
          </Link>
          <Link to="/nevroherb">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              нервна система
            </Button>
          </Link>
          <Link to="/menopauzal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              менопауза
            </Button>
          </Link>
          <Link to="/liporedukt">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              наднормено тегло
            </Button>
          </Link>
          <Link to="/laksiprokt">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              лениви черва
            </Button>
          </Link>
          <Link to="/prostatizal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              простата
            </Button>
          </Link>
          <Link to="/psoriazal">
            <Button
              variant="primary"
              className="health-pill
          "
              size="sm"
            >
              сарсапарила
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h5 className="section-heading">Полезна информация</h5>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-12 col-sm-6">
          <Link to="/technology" className="link-no-style">
            <Card className="d-flex flex-row info-button mb-4">
              <Card.Img
                className="rounded-left"
                src={TechImg}
                alt="Licaps&#8482; технология на производство"
              />
              <Card.Body>
                <Card.Text className="p-card-text">
                  Licaps&#8482; технология на производство
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
          <Link to="/articles" className="link-no-style">
            <Card className="d-flex flex-row info-button mb-4">
              <Card.Img className="rounded-left" src={StatiiImg} alt="Статии" />
              <Card.Body>
                <Card.Text className="p-card-text">Статии</Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col className="col-12 col-sm-6">
          <Link
            to="/articles/kakvo-e-hialuronova-kiselina"
            className="link-no-style"
          >
            {/* <Card className="d-flex flex-row info-button mb-4">
              <Card.Img
                className="rounded-left"
                src={HyaluronicImg}
                alt="Какво е хиалуронова киселина?"
              />
              <Card.Body>
                <Card.Text className="p-card-text">
                  Какво е хиалуронова киселина?
                </Card.Text>
              </Card.Body>
            </Card> */}
          </Link>
          <Link to="/about" className="link-no-style">
            <Card className="d-flex flex-row info-button mb-4">
              <Card.Img
                className="rounded-left"
                src={AboutImg}
                alt="За Вита Херб"
              />
              <Card.Body>
                <Card.Text className="p-card-text">За Вита Херб</Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default IndexPage
